<template>
  <div>
    <b-row class="" align-h="center">
      <b-col cols="12">
        <Card class="py-4 px-4 login-card">
          <template v-slot:content>
            <!--begin::Signin-->
            <div class='login-form login-signin'>
              <div class='text-center'>
                <b-iconstack font-scale="5" class="mb-5" >
                  <b-icon stacked icon="shield-fill" variant="success" scale="1"></b-icon>
                  <b-icon stacked icon="check" variant="white"></b-icon>
                </b-iconstack>

                <h4>{{ $t('GENERAL.congratulations') }}</h4>

                <p class="paragraph">{{ $t('AUTH.activate_account_success_description') }}</p>

                <router-link :to="{ name: 'login' }" class="btn btn-primary mt-3">{{ $t('GENERAL.back_to_home') }}</router-link>

                <!-- Hidden until we find a secure way -->
                <!-- <span class="resend-link" @click="resendLink">¿No te ha llegado? Volver a enviar</span> -->
              </div>
            </div>
            <!--end::Signin-->
          </template>
        </Card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ToastsMixin from '@/services/mixins/toasts.mixins'

export default {
  name: 'ActivateAccountSuccess',
  mixins: [ToastsMixin],
  data () {
    return {
      form: {
        name: '',
        email: '',
        password: ''
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important
}

.resend-link {
  margin-top: 1rem;
  display: block;
  color: $primary-dark;

  cursor: pointer;
}
</style>
