import { render, staticRenderFns } from "./ActivateAccountSuccess.vue?vue&type=template&id=1fde3988&scoped=true&"
import script from "./ActivateAccountSuccess.vue?vue&type=script&lang=js&"
export * from "./ActivateAccountSuccess.vue?vue&type=script&lang=js&"
import style0 from "./ActivateAccountSuccess.vue?vue&type=style&index=0&id=1fde3988&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fde3988",
  null
  
)

export default component.exports